import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
// import Products from './pages/Products';
// import Error from './pages/Error';
// import PrivacyPolicy from './pages/PrivacyPolicy';
const Products = lazy(() => import('./pages/Products'));
const Error = lazy(() => import('./pages/Error'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
import Header from './components/Header';
import ScrollToTop from './vendor/ScrollToTop';

const Loader = () => {
	return (
		<div className="loader-wrapper">
			<div className="sk-chase">
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
			</div>
		</div>
	);
};

const App = () => {
	return (
		<main>
			<Suspense fallback={<Loader />}>
				<Switch>
					<Route path="/" component={Home} exact />
					<Route path="/products" component={Products} />
					<Route path="/privacypolicy" component={PrivacyPolicy} />
					<Route component={Error} />
				</Switch>
			</Suspense>
		</main>
	);
};

render(
	<BrowserRouter>
		<Header />
		<ScrollToTop />
		<App />
	</BrowserRouter>,
	document.getElementById('root')
);
