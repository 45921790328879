import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
	return (
		<>
			<nav className="nav nav--header">
				<div className="nav__left">
					<NavLink to="/" exact>
						<img
							src="../../assets/img/SwadeshiTechLogoNew.svg"
							alt="ST"
							className="nav__icon"
						/>
					</NavLink>
				</div>
				<div className="nav__right">
					<NavLink
						to="/"
						exact
						className="nav__link"
						activeClassName="nav__link--active"
					>
						Apps
					</NavLink>
					<NavLink
						to="/products"
						className="nav__link"
						activeClassName="nav__link--active"
					>
						Products
					</NavLink>
				</div>
			</nav>
			<header className="header">
				<h1 className="header__heading">Swadeshi Tech</h1>
				<h1 className="header__sub-heading">
					Find Indian Apps and Products
				</h1>
			</header>
		</>
	);
};

export { Header as default };
