import React, { Suspense, lazy } from 'react';
const CategoryNav = lazy(() => import('../components/CategoryNav'));
const Search = lazy(() => import('../components/Search'));
const AppSection = lazy(() => import('../components/Card'));
const About = lazy(() => import('../components/About'));
const ShareSection = lazy(() => import('../components/Share'));
import { appCategories } from '../data/apps.json';

const Loader = () => {
	return (
		<div className="loader-wrapper">
			<div className="sk-chase">
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
				<div className="sk-chase-dot"></div>
			</div>
		</div>
	);
};

let appCategoryLinks = appCategories;

const Home = () => {
	const appSectionElements = [];
	appCategoryLinks.forEach((elem) => {
		appSectionElements.push(
			<AppSection key={elem.id} index={elem.id} heading={elem.name} />
		);
	});
	return (
		<>
			<section>
				<Suspense fallback={<Loader />}>
					<CategoryNav linksList={appCategoryLinks} />
					<Search />
					<h2
						style={{
							fontSize: '16px',
							textAlign: 'center',
							color: '#666',
							fontWeight: '400',
							marginTop: '20px',
							padding: '0 20px',
						}}
					>
						Click on any app to download it from the Play Store /
						App Store.
						{/* <pre>{JSON.stringify(chineseApps, null, 4)}</pre> */}
					</h2>
					{appSectionElements}
				</Suspense>
			</section>
			<Suspense fallback={<Loader />}>
				<ShareSection page="apps" />
				<About />
			</Suspense>
		</>
	);
};

export { Home as default };
